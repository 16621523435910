import React from 'react'

function Pnf() {
  return (
    <div style={{marginTop:"200px"}} >
      <div className="mt-5 text-center">
       <h1> PAGE NOT FOUND</h1>
       <h6> Go back to Home</h6>
      </div>
    </div>
  )
}

export default Pnf