import React, { useState } from "react";
import "./CS.css"; 
import cs from "../images/cs.avif";

const App = () => {
  const [showHorizontalLine, setShowHorizontalLine] = useState(false);
  const [showVerticalLines, setShowVerticalLines] = useState([]);
  const totalVerticalLines = 5;
  const lineTexts = [
    "CUSTOMER CENTRIC",
    "COST EFFICIENT",
    "CONVENIENT",
    "COMPASSIONATE",
    "CONVENTIONAL",
  ];

  const handleClick = () => {
    setShowHorizontalLine(true); 

    
    for (let i = 0; i < totalVerticalLines; i++) {
      setTimeout(() => {
        setShowVerticalLines((prev) => [...prev, i]); 
      }, 1000 * (i + 1));
    }
  };

  return (
    <>
      <h1 className="how-mobile-version text-center pb-5">HOW ARE WE DIFFERENT?</h1>
      <img className="lines-image" src={cs} alt="Cover" />
      <div className="lines-cover"></div>
      <h1 className="icon1" onClick={handleClick}>
        5c's
      </h1>
      <div className="lines-section">
        <div className="lines-container">
          <div className="icon-lines-text">
            <h1 className="icon">5c's</h1>
          </div>
          {showHorizontalLine && (
            <div className="horizontal-line animated"></div>
          )}
          {showVerticalLines.map((index) => (
            <div
              key={index}
              className="vertical-line animated"
              style={{ left: `${130 + 400 * index}%`, top: "259%" }}
            >
              <div className="line-text">
                <p className="line-texts">{lineTexts[index]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default App;
