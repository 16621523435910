import React from "react";
import ftimg from "../images/ftimg.png";
import fb from "../images/fc.png";
import link from "../images/link.png";
import you from "../images/you.png";
import insta from "../images/insta.png";
import twi from "../images/twi.png";
import rtarrow from "../images/rtarrow.png";
import { NavLink } from "react-router-dom";
import whatsapp from "../images/whatsapp.png";
import { SlSocialInstagram } from "react-icons/sl";
function Footer() {
  const handleWhatsAppClick = () => {
    const phoneNumber = "8550077883"; // Replace with the desired phone number
    const message = encodeURIComponent("Hello, this is a pre-filled message.");

    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`,
      "_blank"
    );
  };
  return (
    <>
      <div
        className="row main"
        style={{
          backgroundColor: "black",
          padding: "36px",
          borderRadius: "16px",
        }}
      >
        <div className="col-md-6 col-lg-3">
          <h5 style={{ color: "white", paddingBottom: "20px" }}>
            <b>Quick Links</b>
          </h5>
          <ul className="disk" style={{ listStyle: "none" }}>
            <li className="p-1">
              <NavLink
                to={"/home"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Home
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/ourstory"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Our Story
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/blog"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Blog
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/contact"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-lg-3">
          <h5
            style={{
              color: "white",
              paddingBottom: "20px",
              paddingLeft: "46px",
            }}
          >
            <b>Quick Links</b>
          </h5>
          <ul className="disk" style={{ listStyle: "none" }}>
            {/* <li className="p-1">
              <NavLink
                to={"/corporate"}
                style={{ textDecoration: "none", color: "white",fontSize:'15px' }}
              >
                Corporate Health Service
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/recruiment"}
                style={{ textDecoration: "none", color: "white",fontSize:'15px' }}
              >
                International Recruitment
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/diagnostic"}
                style={{ textDecoration: "none", color: "white",fontSize:'15px' }}
              >
                Medical and Diagnostics Services
              </NavLink>
            </li> */}
            <li className="p-1">
              <NavLink
                to={"/management"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Hospital Consultancy Management
              </NavLink>
            </li>
            <li className="p-1">
              <NavLink
                to={"/healthcaretourism"}
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "15px",
                }}
              >
                Healthcare Tourism
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="border p-3">
            <h5 className="font-weight-bold" style={{ color: "white" }}>
              <b>Subscribe</b>
            </h5>
            <div className="d-flex">
              <input
                type="text"
                className="mobile-version-subscribe mt-2 mb-2"
                placeholder="Enter Email Address"
              />

              <img
                src={rtarrow}
                alt=""
                className="mt-2"
                style={{ width: "50px", height: "45px" }}
              />
            </div>
            <p style={{ color: "white" }}>
              Sign up for our newsletter to stay updated with the latest news.
            </p>
          </div>
        </div>
      </div>

      <div className="row main d-flex justify-content-around align-items-center social">
        <div className="col-md-3 socaillogo">
          <img src={ftimg} alt="" className="img-fluid" />
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-center align-items-center  terms">
            <p>Terms of Service </p> &nbsp; &nbsp; &nbsp;{" "}
            <p> Privacy Policy </p>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="d-flex justify-content-evenly align-items-center social">
            <div>
              <a
                href="https://www.facebook.com/HealthtreeServicesPrivateLimited/"
                target="_blank"
              >
                <img src={fb} alt="" className="img-fluid" />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/87209560/admin/feed/posts/"
                target="_blank"
              >
                <img src={link} alt="" />
              </a>
            </div>
            <div className="circle">
              <a href="https://www.instagram.com/healthtree_services_pvt_ltd?igsh=MWlkNGtlbXVocWk3OA==" target="_blank" style={{ color: "black",fontWeight:'800' }}>
                <SlSocialInstagram  style={{fontSize:'26px',paddingTop:'0',marginTop:'0',fontWeight:'800'}}/>
              </a>
            </div>
            {/* <div>
              <a href="https://www.instagram.com/" target="_blank">
                <img src={insta} alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/" target="_blank">
                <img src={you} alt="" />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/" target="_blank">
                <img src={twi} alt="" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer">
        <p className="footertext">
          Copyright © 2023 | HealthTree Services Private Limited . All rights
          reserved.
        </p>
      </div>
      <div className="whatsapp">
        {/* <button onClick={handleWhatsAppClick}>Send WhatsApp Message</button> */}
        <img
          src={whatsapp}
          alt=""
          className="img-fluid"
          onClick={handleWhatsAppClick}
        />
      </div>
    </>
  );
}

export default Footer;
