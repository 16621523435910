import React, { useState } from 'react';

function NewsLetters() {
  // URLs or paths to your PDF brochures
  const brochureUrls = [
    `${process.env.PUBLIC_URL}/Dengue.pdf`,
    `${process.env.PUBLIC_URL}/Summer Health Woes.pdf`,
    // Add more brochure URLs as needed
  ];

  const [currentBrochureIndex, setCurrentBrochureIndex] = useState(0);

  const extractFileName = (url) => {
    // Extract file name from URL
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1]);
  };

  const handleDownload = (url) => {
    // Create an anchor element and trigger download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', extractFileName(url)); // Set download attribute to suggest the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="text-center p-5  weight-600" style={{marginTop:'2rem'}}>
        <h1 style={{ fontWeight: "600" }}>
          Our
          <span
            style={{
              color: "brown",
              paddingLeft: "20px",
              fontWeight: "600",
            }}
          >
            NewsLetters
          </span>
        </h1>
      </div>
      <div className='brochures' style={{display:'flex', justifyContent:'space-around'}}>
        {/* View Button */}
        <a className='view-brochures' href={brochureUrls[currentBrochureIndex]} target="_blank" rel="noopener noreferrer">
          <button className='btn'>View {extractFileName(brochureUrls[currentBrochureIndex])}</button>
        </a>
        {/* Download Button */}
        <button className='btn' onClick={() => handleDownload(brochureUrls[currentBrochureIndex])}>Download {extractFileName(brochureUrls[currentBrochureIndex])}</button>
        {/* Button to switch to the next brochure */}
        <button className='btn-brochure' onClick={() => setCurrentBrochureIndex(prevIndex => (prevIndex + 1) % brochureUrls.length)}>Next</button>
      </div>
    </>
  );
}

export default NewsLetters;