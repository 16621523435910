import React from "react";
import { CgMail } from "react-icons/cg";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
export default function Contact() {
  return (
    <div
      className=" contact-top-section fixed-top"
      style={{ backgroundColor: "#0e1110" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 66px",
        }}
      >
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CgMail className="contact-icons" />{" "}
          <a
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "12px",
              cursor: "pointer",
            }}
            href=""
          >
            support@healthtree.co.in
          </a>
          <FaPhoneAlt className="contact-icons" />
          <a style={{ cursor: "pointer", fontSize: "12px" }}>+91 8550077883</a>
          <a
            style={{ color: "white" }}
            href="https://api.whatsapp.com/send?phone=8550077883&text=Hello%2C%20this%20is%20a%20pre-filled%20message."
            target="_blank"
          >
            <FaWhatsapp className="contact-icons" />
          </a>
          <a style={{ cursor: "pointer", fontSize: "12px" }}>+91 8550077883</a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            style={{ color: "white" }}
            href="https://www.facebook.com/HealthtreeServicesPrivateLimited/"
            target="_blank"
          >
            <FaFacebook className="contact-icons" />
          </a>
          <a
            style={{ color: "white" }}
            href="https://www.linkedin.com/company/healthtree-services-pvt-ltd/"
            target="_blank"
          >
            <FaLinkedin className="contact-icons" />
          </a>

          <a
            style={{ color: "white" }}
            href="https://api.whatsapp.com/send?phone=8550077883&text=Hello%2C%20this%20is%20a%20pre-filled%20message."
            target="_blank"
          >
            <FaWhatsapp className="contact-icons" />
          </a>
          <a
            style={{ color: "white" }}
            href="https://www.instagram.com/healthtree_services_pvt_ltd?igsh=MWlkNGtlbXVocWk3OA=="
            target="_blank"
          >
            <SlSocialInstagram className="contact-icons" />
          </a>
        </div>
      </div>
    </div>
  );
}
