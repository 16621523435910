import React from "react";
import "./navbar.css";
import { NavLink } from "react-router-dom";
import logo from "../images/image 40.png";
function Navbar() {
  return (
    <>
      <a
        className="close-navbar-toggler collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#menu"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      ></a>
      <nav className="navbar navbar-expand-lg bg-light fixed-top">
        <div className="container">
          <div className="logo">
            <NavLink to={"/"} className="navbar-brand">
              <img src={logo} alt="Health Tree" className="img-fluid" />
            </NavLink>
          </div>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="menu"
          >
            <ul className="navbar-nav">
              <li className="nav-item customnav zoom-on-hover">
                <NavLink to={"/ourstory"} className="nav-link ">
                  OUR STORY
                </NavLink>
              </li>
              <li className="nav-item customnav active zoom-on-hover">
                <NavLink to={"/recruiment"} className="nav-link">
                  INTERNATIONAL RECRUITMENT
                </NavLink>
              </li>
              <li className="nav-item customnav zoom-on-hover">
                <NavLink to={"/corporate"} className="nav-link ">
                  CORPORATE HEALTHCARE SOLUTIONS
                </NavLink>
              </li>
              <li className="nav-item customnav zoom-on-hover">
                <NavLink to={"/diagnostic"} className="nav-link ">
                  MEDICAL AND DIAGNOSTIC SERVICES
                </NavLink>
              </li>
              <li className="nav-item customnav zoom-on-hover">
                <NavLink to={"/management"} className="nav-link ">
                HOSPITAL CONSULTANCY MANAGEMENT
                </NavLink>
              </li>
              {/* <li className="nav-item customnav zoom-on-hover">
                <NavLink to={"/healthcaretourism"} className="nav-link ">
                HEALTHCARE TOURISM
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
