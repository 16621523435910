import React, { useState, useEffect } from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Main from "./components/Main";
import Contact from "./components/screens/Contact.js";
import Popup from "./components/screens/Popup.js";

function App() {
  const [showPopup, setShowPopup] = useState(true);

  return (
    <>
      <div>{showPopup && <Popup onClose={() => setShowPopup(false)} />}</div>
      <div className="App">
        <Contact />
        <Main />
      </div>
    </>
  );
}

export default App;
