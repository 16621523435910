import React from "react";
import managemnet from "../images/managemnet.png";
import health2 from "../images/health2.png";
import health3 from "../images/health3.png";
import health4 from "../images/health4.png";
import health6 from "../images/health6.png";
import health7 from "../images/health7.png";
import health5 from "../images/health-5.png";
import circle from "../images/circle.png";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img3 from "../images/img3.png";
import img4 from "../images/img4.png";
import img5 from "../images/img5.png";
import img6 from "../images/img6.png";
import img7 from "../images/img7.png";
import img8 from "../images/img8.png";
import img9 from "../images/img9.png";
import img10 from "../images/img10.png";
import img11 from "../images/img11.png";
import microscope from "../images/microscope.png";

function ConsultancyManagement() {
  const data = [
    { img: img1, text: "Gap Analysis & Bench Marking survey Goals" },
    { img: img2, text: "Hospital Planning- New/Restructuring, Re-Architecture/Renovating." },
    { img: img3, text: "Final Hospital Audits- Validating Business Plan- Complete Business Project Report." },
    { img: img4, text: "Strategic Operations, Administrative and Facility Management." },
    { img: img5, text: "Hospital Supplies and inventory Management" },
    { img: img6, text: "Hospital Admin Facilities Management." },
    { img: img7, text: "Hospital Quality and Accreditations.(JCI, NABH, NABL, ISO, etc)" },
    { img: img8, text: "Hospital Digitalization and information systems management." },
    { img: img9, text: "Statutory requirement-compliance Legalities and Implications." },
    { img: img10, text: "Marketing, Promotion and Brand Management." },
    { img: img11, text: "Procurement- Validation and vendor management, and many More Services," },
    // Add more objects as needed
  ];
  return (
    <>
     <div className="row" style={{marginTop:'9.4rem'}}>
          <div className="col-md-3 p-5">
            <h3>What We Do?</h3> <div className="bar1"></div>
            <p className="pt-3 pb-3 lh-lg">
              Total Hospital & Healthcare Management Services of Clinic's,
              Diagnostic's, Poly Clinic's, Primary Units, Nursing home's,
              Secondary and Tertiary Care Units
            </p>
          </div>
          <div className="col-md-9">
            <div className="row">
              {data.map((item, index) => (
                <div className="col-md-4 p-4">
                  <div key={index}>
                    <img
                      src={item.img}
                      aalt={`Image ${index}`}
                      className="img-fluid"
                    />
                  </div>
                  <p className="countpt">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="main">
          <div className="row" style={{display:'flex',alignItems:'center'}}>
            <div className="col-md-6">
              <div>
                <img src={microscope} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="p-4">
                Advanced Medical Services Tailored for Corporate Clients
              </h3>
              <p className="p-4 lh-lg">
                At Health Tree Medical and Diagnostics, we prioritize accuracy,
                precision, and reliability in every aspect of our services. With
                over a decade of experience, our centrally located and easily
                accessible facility is dedicated to serving corporate clients
                with fast, reliable, and cost-efficient medical reports.
              </p>
              <p className="px-4 lh-lg">
                We believe in the importance of early detection, prevention, and
                immediate attention to ensure optimal healthcare outcomes. Our
                comprehensive range of diagnostic services includes
                state-of-the-art technologies and expert medical professionals.
                From radiology and pathology to cardiology and specialized
                screenings, we are committed to delivering top-quality services
                that promote well-being and proactive healthcare management.With
                HealthTree Medical and Diagnostics, you can trust in our
                expertise, efficiency, and unwavering commitment to your health
                and wellness.
              </p>
            </div>
          </div>

         
        </div>

      {/* <div
        className="row"
        style={{ background: "#005198", marginTop: "100px" }}
      >
        <div className="col-md-6">
          <h1 className="recbanner1">
            A-Z of Complete business needs, met with utmost ease, flexibility
            and efficiency.
          </h1>
        </div>
        <div className="col-md-6">
          <div>
            <img src={managemnet} className="img-fluid pe-4" alt="" />
          </div>
        </div>
      </div>
      <div className="main">
        <div className="text-center">
          <h2>Hospital Consultancy Management</h2>
          <p className="p-3 lh-lg">
            Health Tree is a composite specialty healthcare firm, and deliver
            advisory and consulting services to both greenfield projects,
            brownfield <br /> and operational healthcare facilities, Services
            are delivered on a project by project basis or as in turnkey
            approach,
          </p>
          <p className="p-3 lh-lg">
            We are thoroughly Professional consultancy firm specializing in
            molding itself to the needs, circumstances and situational demands
            to <br /> cater to needs of individual clinics, IT, ITES, BPO, Call
            centers, MNC’s, Factories, Industries at the corporate front and
            Laboratories, <br /> polyclinics, Primary healthcare centers to high
            end tertiary care hospitals @ healthcare verticals...rendering end
            to end services-solutions <br /> in the most tailor made fashion.
          </p>
          <p className="p-3 lh-lg">
            Founded - Promoted by Dr Ahmed A Khan who pioneered ‘Corporate
            Healthcare Services’ in India, in 2004 and has been at the forefront
            of <br /> uniquely conceptualized Hospital Management services since
            2009, working along with a amalgamated team of expert, potential and{" "}
            <br />
            talented allied professionals. We take special care to tackle all
            tasks handed over to us, in a decisive way and make it a point to
            deliver <br /> effectual solutions swiftly and promptly. Which has
            gained us a family of 27 corporate clients across many cities.
          </p>
        </div>

        <div className="mt-5">
          <div className="d-flex">
            <div className="bar5"></div>
            <h2 className="serviceh1">Feasibility Study</h2>
          </div>

          <div className="row">
            <div className="col-md-6">
              <img src={health2} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6 p-3">
              <p className="lh-lg">
                In the Feasibility Study, we conduct a thorough analysis of your
                proposed project to determine its viability and potential for
                success.
              </p>
              <p className="lh-lg pt-3">
                Our experienced team evaluates various aspects, including market
                conditions, financial projections, regulatory compliance, and
                resource requirements.
              </p>
              <p className="lh-lg pt-3">
                This comprehensive study aims to provide you with valuable
                insights and data-driven recommendations, empowering you to make
                informed decisions about whether the project should proceed or
                not.
              </p>
              <p className="lh-lg pt-3">
                With HealthTree's Feasibility Study, you can confidently move
                forward with projects that align with your goals and have a high
                likelihood of success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#8FE4FF" }}>
        <div className="main row">
          <div className="col-md-6 p-5">
            <h2 className="managepara">
              Strategic Operations, Administration and Facility Management.
            </h2>
            <p className="lh-lg">
              Administration and operational management of Medical, nursing,
              Para-medical and ancillary activities. Day to day operational
              management of all departments, Individuals and support
              system-process.
            </p>
            <p className="pl-pg">
              We Focus on improving a handful of metrics identified as most
              important. Practice good asset control- Creating a positive and
              productive work culture through leadership. Setting the standards
              for excellence in operations.
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <img src={health3} alt={""} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="main row">
        <div className="col-md-6">
          <div>
            <img src={health4} alt={""} className="img-fluid" />
          </div>
        </div>
        <div className="col-md-6 p-5">
          <h2 className="managepara">
            Human Resource Suppy, Commissioning, Training and Operations
          </h2>
          <p className="lh-lg">
            Integrated, functions covering from hiring to performance
            management, safety and team co ordination, HR plays extremely vital
            role in delivery of healthcare services.
          </p>
          <p className="pl-pg">
            HealthTree takes care of the complete spectrum of Human Resource
            functions ranging from hiring of clinical - Non clinical,
            administrative, Allied healthcare development, counselling,
            compensation and rewards management, in service education and
            statuory regulations and compliance and exclusive tailor made
            selection to suit each hospital counsidering the pulse of business
            in an all inclusive expertise that HealthTree offers its clients.
          </p>
        </div>
      </div>
      <div style={{ background: "#8FE4FF" }}>
        <div className="main row">
          <div className="col-md-6 p-5">
            <h2 className="managepara">Hospital-Quality and Accreditations</h2>
            <p className="lh-lg">
              Quality as a canopy and universal policy over entire is provided
              by HealthTree with specific eye for detail so as to meet the
              internal and external demands thereby impacting from A-Z of all
              functions and further enhancing all service each passing day.
            </p>
            <p className="pl-pg">
              Current scenarios and compliance are making it mandatory to all
              units to implicate Quality. HT guides and implicates accrediting
              as deemed necessary to each client, based upon the needs. ISO,
              NABL,NABH, JCI, ISQua, CAP, MCI, QCI, etc.
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <img src={health5} alt={""} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="main row">
        <div className="col-md-6">
          <div>
            <img src={health6} alt={""} className="img-fluid" />
          </div>
        </div>
        <div className="col-md-6 p-5">
          <h2 className="managepara">Hospital- Digitalization</h2>
          <p className="lh-lg">
            Considering the numerous benefits that comes alog with
            digitalization, alog with its needs for accreditions and govt norms,
            it is absolutely necessary for all healthcare services providers to
            cater in tune and/or beyond thebenchmark needsof current practices.
          </p>
          <p className="pl-pg">
            HealthTree strongly advocates and implements the same in areas
            pertaining to storage Capabilities, Communication amoung staff
            Security and Privacy, Increased Efficiency, Cost reduction. stock
            Maintanence, One Click MIS Report's and much more.
          </p>
        </div>
      </div>
      <div className="mt-5 main">
        <div className="d-flex">
          <div className="bar5"></div>
          <h2 className="serviceh1">Why Health Tree</h2>
        </div>

        <div className="row">
          <div className="col-md-6 p-3">
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                Health Tree is a multifaceted firm which allows the client to
                have easy access on any/and every service required.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL focuses on problem identification and solution's rather
                than assumption's and unrealistic approach.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL acts as a catalyst between management & employees alike,
                integrally working within the system.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL relies strongly on its ability to self change, encourage
                change & impact on change in a through n through process.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL is uniquely flexible and sensitive to varing mind set,
                culture, practices and community needs of businesses.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid pt-2" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL is backed by years of study and research on hospital
                standards & current scenario for growth.
              </p>
            </div>
            <div className="d-flex pt-2">
              <div>
                <img src={circle} alt="" className="img-fluid" />
              </div>
              <p className="ps-3 lh-lg">
                HTSPL with dedicated line of account managers, with
                individuality of  <br /> experience, professionalism that is monitored
                form grass root level <br /> to complete processes is also a
                differentiating tool that helps us stand <br /> distinctively with our
                competitors.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img src={health7} alt="" className="img-fluid" />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ConsultancyManagement;
