// Popup.js
import React from 'react';

const Popup = ({ onClose }) => {
  return (
    
    <>
     <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        zIndex: 999 
      }}></div>
      {/* Popup */}
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        zIndex: 1000, 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '80%',
        maxWidth: '450px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        textAlign:'center'
      }}>
        <h2 style={{color:'blue'}}>Book Your Appoinment </h2>
        <h1>+91 8550077883</h1>
        <p>For Immediate Assistance</p>
        <button onClick={onClose} style={{ 
            marginTop: '10px', 
            padding: '5px 20px', 
            backgroundColor: '#007bff', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px',
            cursor: 'pointer',
        }}>Close</button>
      </div>
    </>
  );
};

export default Popup;
